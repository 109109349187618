/*
---
name: アーカイブナビ_通常
category: Navigation
---
*/
.c-box-archive {
  &__block {
    border: 1px solid $color-primary;
    padding: rem-calc(24) rem-calc(32) rem-calc(12);
    margin-bottom: rem-calc(16);
    @include breakpoint(small only) {
      padding: rem-calc(16) rem-calc(24) rem-calc(12);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    font-size: rem-calc(20);
    font-weight: 700;
    letter-spacing: 0.1em;
    color: $color-primary;
    margin-bottom: rem-calc(16);
    @include breakpoint(small only) {
      font-size: rem-calc(16);
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      margin-right: rem-calc(48);
      margin-bottom: rem-calc(12);
      @include breakpoint(small only) {
        margin-right: rem-calc(24);
        margin-bottom: rem-calc(8);
      }
      &::before {
        content: "chevron_right";
        font-family: 'Material Icons Outlined';
        line-height: 1;
        letter-spacing: 0;
        color: $color-primary;
        padding-right: rem-calc(8);
      }
      a {
        color: $font-base-color;
        text-decoration: none;
        font-weight: 400;
      }
    }
  }
}
