.c-block-modal-form {
  position: fixed;
  top: rem-calc(80);
  left: 0;
  width: 100%;
  z-index: 99999;
  background: rgba($font-base-color, .9);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;

  @include breakpoint(medium down) {
    top: rem-calc(55);
  }

  &__inner {
    padding: rem-calc(90) 0;
    position: relative;

    @include breakpoint(small only) {
      padding: rem-calc(60) 0;
    }
  }

  &__bg {
    position: fixed;
    left: 0;
    height: calc(100vh - 352px);
    width: 100%;
    bottom: 0;

    @include breakpoint(medium down) {
      height: calc(100vh - 252px - 64px);
    }

    @include breakpoint(small only) {
      height: calc(100vh - 166px - 64px);
    }
  }

  &__form {
    display: flex;
    height: rem-calc(72);
    width: 100%;
    max-width: rem-calc(748);
    margin: auto;
    position: relative;

    @include breakpoint(small only) {
      height: rem-calc(46);
    }

    &::after {
      content: "";
      height: rem-calc(46);
      width: 1px;
      background: $border-base-color;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: rem-calc(62);

      @include breakpoint(small only) {
        height: rem-calc(22);
        left: rem-calc(42);
      }
    }

    .is-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: rem-calc(22);
      width: rem-calc(21);
      height: rem-calc(21);
      z-index: 1;
      pointer-events: none;

      @include breakpoint(small only) {
        font-size: rem-calc(10);
        transform: scale(.9) translateY(-50%);;
        left: rem-calc(12);
      }
    }

    input {
      border: none;
      font-family: inherit;
      position: relative;
      padding-left: rem-calc(86);
      @include font-format(14,.1,20,400);
      border-radius: 2px 0 0 2px;

      @include breakpoint(small only) {
        padding-left: rem-calc(60);
        @include font-format(11,.08,17,400);
      }

      &::placeholder {
        @include font-format(14,.1,20,400);
        color: $color-gray;
        @include breakpoint(small only) {
          @include font-format(11,.08,17,400);
        }
      }
    }

    button {
      width: rem-calc(112);
      flex: none;
      background: $color-primary;
      border: none;
      padding: 0;
      color: $color-white;
      outline: none;
      @include font-format(16,.1,24,400);
      border-radius: 0 2px 2px 0;
      transition: all 0.2s ease-out;

      @include breakpoint(small only) {
        width: rem-calc(78);
        @include font-format(12,.08,18,400);
      }

      &:hover {
        opacity: .5;
      }
    }
  }

  &__close {
    position: absolute;
    right: rem-calc(140);
    top: rem-calc(24);
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: rem-calc(53.2);
    height: rem-calc(53.2);
    @include transition();

    @include breakpoint(1300px down) {
      right: rem-calc(20);
    }

    @include breakpoint(small only) {
      right: 0;
      top: rem-calc(6);
    }


    &::before {
      content: "";
      display: block;
      width: rem-calc(53.2);
      height: 1px;
      background: $color-white;
      transform: rotate(45deg);

      @include breakpoint(small only) {
        width: rem-calc(40);
      }
    }

    &::after {
      content: "";
      display: block;
      width: rem-calc(53.2);
      height: 1px;
      background: $color-white;
      transform: translateY(-1px) rotate(-45deg);

      @include breakpoint(small only) {
        width: rem-calc(40);
      }
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

body.is-search-modal-open {
  .c-block-modal-form {
    visibility: visible;
    opacity: 1;
  }
}
