/*
---
name: フッター_サイトマップ型
category: Layout
---
*/

.l-footer {
  position: relative;
  background-color: $color-primary-dark;
  color: $color-white;
  padding-bottom: rem-calc(64);
  @include breakpoint(medium down) {
    padding-bottom: rem-calc(80);
  }
  &__menu {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: rem-calc(77);
    padding-bottom: rem-calc(0);
    @include breakpoint(medium down) {
      display: block;
      width: 100%;
      padding-top: rem-calc(50);
      padding-bottom: rem-calc(50);
    }
  }
  .row {
    justify-content: space-between;
    margin: 0;
    @include breakpoint(medium down) {
      justify-content: center;
    }
  }
  &__contents {
    padding-top: rem-calc(76);
  }
  &__logo {
    margin: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    color: $color-white;
    text-decoration: none;
    small {
      display: block;
      @include font-format(10, 0.05, 17.5, 700);
      margin-bottom: rem-calc(8);
      @include breakpoint(medium down) {
        margin-bottom: rem-calc(4);
      }
    }
    &__content {
      display: flex;
      @include font-format(19, 0, 33, 700);
    }
    &__images {
      margin-right: 8px;
      img {
        @include transition();
        width: 31px;
        height: 31px;
      }
    }
  }
  &__store {
    margin: 0;
    flex-shrink: 0;
    color: $color-white;
    text-decoration: none;
    display: flex;
    align-items: center;
    @include font-format(13, 0, 22, 400);
    margin-top: rem-calc(31);
    &__images {
      margin-right: 11px;
      width: 120px;
      img {
        display: block;
        width: auto;
        height: auto;
      }
    }
  }
  &__block {
    &:nth-child(2) {
      padding: 0 rem-calc(24) 0 rem-calc(74);
      @include breakpoint(medium down) {
        padding: 0;
      }
    }
    @include breakpoint(medium down) {
      padding: 0;
    }
    &.js-accordion {
      @include breakpoint(medium down) {
        // border-bottom: 1px solid #ccc;
        .l-footer__menutitle {
          // border-bottom: unset;
          position: relative;
          &::after,
          &::before {
            content: "";
            display: block;
            width: rem-calc(2);
            height: rem-calc(18);
            background-color: $color-white;
            position: absolute;
            top: 50%;
            right: rem-calc(24);
            transform: translateY(-50%);
          }

          &::after {
            width: rem-calc(18);
            height: rem-calc(2);
            right: rem-calc(16);
          }
          &::before {
            padding-right: unset;
          }
        }
      }
      &.is-open {
        .l-footer__menutitle {
          &::before {
            display: none;
          }
        }
      }
    }
  }
  &__menutitle {
    span {
      @include font-format(16, 0.05, 28, 700);
      display: block;
      margin-bottom: rem-calc(16);
      @include breakpoint(medium down) {
        display: inline;
      }
    }
    @include breakpoint(medium down) {
      border-bottom: 1px solid $border-base-color;
      padding: rem-calc(12) rem-calc(10);
      margin: 0;
      &::before {
        content: "chevron_right";
        font-family: "Material Icons Outlined";
        line-height: 1;
        letter-spacing: 0;
        color: $color-white;
        padding-right: rem-calc(12);
      }
      &.is-open {
        &::before {
          content: "\f106";
        }
      }
    }
    > span {
      @include breakpoint(950px up) {
        display: none;
      }
    }
    > a {
      color: $color-white;
      text-decoration: none;
      @include font-format(16, 0.05, 28, 700);
      display: block;
      margin-bottom: rem-calc(16);
      @include breakpoint(medium down) {
        display: none;
      }
    }
  }
  &__menulist {
    padding-bottom: rem-calc(24);
    @include breakpoint(medium down) {
      padding-bottom: unset;
    }
    li {
      margin-bottom: rem-calc(16);
      @include breakpoint(medium down) {
        margin: 0 !important;
        border-bottom: 1px solid $border-base-color;
      }
      a {
        color: $color-white;
        text-decoration: none;
        @include font-format(16, 0.05, 28, 700);
        display: block;
        margin-bottom: rem-calc(16);
        @include breakpoint(medium down) {
          padding: rem-calc(14) rem-calc(10);
          display: block;
          margin: unset;
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &.is-sub {
      @include breakpoint(medium down) {
        display: none;
        border-bottom: 1px solid $border-base-color;
        padding-left: 1rem;
      }
      li {
        margin-bottom: rem-calc(10);
        @include breakpoint(medium down) {
          &:last-child {
            border: unset;
          }
        }
        &.is-parent {
          span {
            @include font-format(14, 0.05, 24.5, 400);
            display: block;
            margin-bottom: rem-calc(0);
            color: rgba($color-white, 0.7);
            @include breakpoint(medium down) {
              padding: rem-calc(5) rem-calc(10);
            }
          }
        }
        a {
          @include font-format(14, 0.05, 24.5, 400);
          display: block;
          margin-bottom: rem-calc(0);
          color: rgba($color-white, 0.7);
          @include breakpoint(medium down) {
            padding: rem-calc(5) rem-calc(10);
          }
        }
      }
    }
    &.is-inner {
      @include breakpoint(medium down) {
        display: block;
        border-bottom: unset;
        padding-left: rem-calc(20);
        border-top: 1px solid #ccc;
      }
      padding-bottom: rem-calc(3);
      li {
        padding-left: rem-calc(16);
        position: relative;
        margin-bottom: rem-calc(3);
        @include breakpoint(medium down) {
          &:last-child {
            border: unset;
          }
        }
        &::after {
          position: absolute;
          content: "";
          width: 8px;
          height: 1px;
          background: rgba($color-white, 0.7);
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          @include breakpoint(medium down) {
            left: rem-calc(10);
          }
        }
        a {
          @include font-format(12, 0.05, 24.5, 400);
        }
      }
    }
  }
  &__address {
    font-style: normal;
    @include font-format(12, 0.05, 21, 400);
    margin-top: rem-calc(37);
    @include breakpoint(medium down) {
      margin: rem-calc(24) 0;
      font-size: rem-calc(12);
    }
  }
  &__text {
    margin-top: rem-calc(25);
    @include font-format(12, 0.05, 21, 400);
  }
  &__copyright {
    @include font-format(10, 0.05, 10, 500);
    font-family: "Helvetica Neue";
    @include breakpoint(medium down) {
      margin-top: 0;
      text-align: center;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__privacy {
    @include font-format(12, 0.05, 12, 400);
    color: $color-white;
    text-decoration: none !important;
  }
}
