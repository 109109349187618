$forms-transition: all ease-in-out .2s !default;

$forms-input-font-size: 15px;
$forms-input-border-color: #ccc;
$forms-input-active-border-color: $color-primary;

input[type="text"],
input[type="url"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="tel"] {
  font-size: $forms-input-font-size;
  line-height: 1.8;
  border: 1px solid $border-base-color;
  padding: rem-calc(8) rem-calc(12);
  max-width: 100%;
  width: 100%;
  transition: $forms-transition;
  background-color: $color-white;
  @include breakpoint(small only) {
    font-size: 16px;
  }
  &:focus,
  &:active {
    @include input-active();
  }
}

// テキストエリア
//
// markup:
// <textarea row="100" col="50" name="" placeholder="テキストエリア"></textarea>
//
// Styleguide 1.6.2
textarea {
  font-size: $forms-input-font-size;
  line-height: 1.8;
  border: 1px solid $border-base-color;
  padding: rem-calc(8) rem-calc(12);
  max-width: 100%;
  width: 100%;
  transition: $forms-transition;
  background-color: $color-white;
  min-height: 200px;
  @include breakpoint(small only) {
    font-size: 16px;
  }
  &:focus,
  &:active {
    @include input-active();
  }
}

// チェックボックス
//
// markup:
// <label for="checkbox"><input id="checkbox" type="checkbox" value="1"> チェックボックス</label>
//
// Styleguide 1.6.3

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  accent-color: $color-primary;
}

// ラジオボックス
//
// markup:
// <label for="radio"><input id="radio" type="radio" value="1"> ラジオボックス</label>
//
// Styleguide 1.6.4

input[type="radio"] {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  accent-color: $color-primary;
}

// セレクトボックス
//
// markup:
// <label for="select">
//    <select id="select" name="select">
//        <option>選択してください</option>
//        <option value="1">サンプルオプション1</option>
//        <option value="2">サンプルオプション2</option>
//        <option value="3">サンプルオプション3</option>
//    </select>
// </label>
//
// Styleguide 1.6.5
select {
  text-transform: none;
  display: block;
  width: 100%;
  height: 48px;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  background-color: $color-white;
  color: rgba($font-base-color,1);
  border: 1px solid $border-base-color;
  border-radius: 0!important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  @include breakpoint(small only) {
    width: 100%;
  }
}

/*  プレイスホルダーの色変更 */
:placeholder-shown {
  color: rgba($font-base-color,0.3);
}

/* Google Chrome, Safari, Opera 15+, Android, iOS */
::-webkit-input-placeholder {
  color: rgba($font-base-color,0.3);
}

/* Firefox 18- */
:-moz-placeholder {
  color: rgba($font-base-color,0.3);
}

/* Firefox 19+ */
::-moz-placeholder {
  color: rgba($font-base-color,0.3);
}

/* IE 10+ */
:-ms-input-placeholder {
  color: #9FA0A0;
}
