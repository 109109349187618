.c-list {
  list-style: none;
  li {
    list-style: inherit;

    &:not(:first-child) {
      margin-top: rem-calc(6);
    }
  }
}


/*
---
name: <ul> 順序なしテキストデフォルト
category: Base
---

*/

.c-list.is-disc {
  padding-left: 1em;
  li {
    text-indent: -1em;

    &::before {
      content: "・";
      color: $font-base-color;
    }
  }

  ul {
    list-style: none;
    padding-left: 1em;
    li {
      text-indent: -1em;
      list-style: none;

      &::before {
        content: "・";
        color: $font-base-color;
      }
    }
  }

  // is-outline の中に入れた場合にmaker削除
  >li{
    list-style: none!important;
  }
}

/*
---
name: <ul> 順序なしテキスト
category: Base
---

*/
.c-list.is-icon {
  padding-left: rem-calc(20);

  @include breakpoint(small only) {
    padding-left: rem-calc(18);
  }
  li {
    position: relative;

    &::before {
      content: "circle";
      font-family: 'Material Icons Outlined';
      font-size: rem-calc(10);
      color: $color-primary;
      position: absolute;
      top: rem-calc(6);
      left: -1.5em;

      @include breakpoint(small only) {
        top: rem-calc(3);
      }
    }

    ul {
      list-style: none;
      padding-left: rem-calc(18);

      li {

        &::before {
          content: "・";
          color: $font-base-color;
        }
      }
    }
  }

  // is-outline の中に入れた場合にmaker削除
  >li{
    list-style: none!important;
  }
}

/*
---
name: <ol> 順序ありテキスト
category: Base
---

*/
.c-list.is-outline {
  padding-left: 1.5em;
  list-style: decimal outside;
  li {
    position: relative;
  }


  // is-disc等の中に入れた場合にbefore削除用
  >li::before{
    content: none;
  }
  // 中に is-disc等入れた場合の調整用
  ul{
    padding-left: 0;
    margin-left: 1em;
  }
}
