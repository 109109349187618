/*
---
name: メインビジュアル_通常
category: Components
---
*/

.c-main-visual {
  width: 100%;
  position: relative;
  // margin-top: -80px;
  &__image {
    width: 100%;
    // height: rem-calc(620);
    height: calc(100vh - 45px);
    top: 0;
    left: 0;
    @include bg-option();
    @include breakpoint(small only) {
      height: rem-calc(625);
      // min-height: 280px;
    }

    &::after {
      @include img-curtain($color-black, 0.2);
      // width: 1400px;
      // height: 755px;
      background: linear-gradient(
        to right,
        #222 0%,
        #272727 58.62%,
        #6b6b6b 100%
      );
      // border: 1px solid #707070;
      opacity: 0.3;
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__bg-text {
    position: absolute;
    z-index: 10;
    top: 45%;
    transform: translateY(-50%);
    left: -0.5%;
  }
  &__text-block {
    position: absolute;
    z-index: 11;
    top: 50%;
    left: calc(84 / 1400 * 100%);
    transform: translateY(-50%);
    padding: 0 20px;
    @include breakpoint(small only) {
      left: 0;
      padding: 20px;
    }
  }
  &__main-text {
    img {
      width: 100%;
    }
  }
  &__sub-text {
    margin-top: rem-calc(41);
    @include font-format(16, 0.05, 28, 700);
    color: $color-white;
  }

  &__button {
    .c-button {
      max-width: rem-calc(252) !important;
      @include breakpoint(medium down) {
        padding: rem-calc(12) rem-calc(24);
        max-width: rem-calc(198) !important;
      }
    }
  }

  // owl.calousel
  .owl-dots {
    position: absolute;
    bottom: rem-calc(32);
    left: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium down) {
      bottom: rem-calc(16);
    }

    .owl-dot {
      margin: 0 rem-calc(6);
      width: rem-calc(12);
      height: rem-calc(12);
      background-color: $color-white;
      border-radius: 1000px;
      display: block;
      outline: none;

      &.active {
        background-color: $color-primary !important;
      }
    }
  }
}

@keyframes zoomUp {
  0% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1); /* 拡大率 */
  }
}

.c-main-visual .owl-item.active {
  animation: zoomUp 5s linear 0s normal both;
}
