// レイアウト
//
// Webサイトのレイアウトに関するコンポーネント群
//
//
// Styleguide 5.0.0

// コンテナー
//
// 全体で共通となるコンテナ
//
// markup:
// <div class="l-container"></div>
//
// Styleguide 5.5.0

.l-container {
  @extend .container;
  @media (max-width: 1200px) {
    padding-right: 32px;
    padding-left: 32px;
  }
  @include breakpoint(small only) {
    padding-right: 16px;
    padding-left: 16px;
  }
  &.is-two-columns {
    display: flex;
    padding: 0!important;
    @media (max-width: 900px) {
      display: block;
    }
  }
  &.is-right {
    padding: 0 0 0 32px;
    margin-left: calc((100% - 1200px) / 2);
    width: calc(100% - ((100% - 1200px) / 2));
    max-width: unset;
    @media (max-width: 1200px) {
      width: calc(100% - 32px);
      padding: 0;
      margin-left: auto;
      margin-right: unset;
    }
  }
}

