.c-top-news {
  margin-top: rem-calc(-98);
  max-width: 658px;
  height: rem-calc(98);
  position: relative;
  z-index: 100;
  background-color: $color-background;
  padding: rem-calc(29) rem-calc(40) rem-calc(33) rem-calc(58);
  border-top: 5px solid $color-primary;
  @include breakpoint(small only) {
    width: 90%;
    height: rem-calc(80);
    padding: rem-calc(10) rem-calc(20) rem-calc(10) rem-calc(30);
    margin-top: rem-calc(-80);
  }
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__heading {
    color: $color-primary;
    @include font-format(24, 0.05, 36, 500);
    margin-right: 20px;
    @include webfont-prompt();
    @include breakpoint(small only) {
      font-size: rem-calc(24) * 0.8;
    }
  }
  &__content {
    max-width: rem-calc(440);
    width: 100%;
  }
  &__item {
    text-decoration: none;
    padding-right: rem-calc(22);
    position: relative;
    width: 100%;
    display: block;
    &::after {
      position: absolute;
      content: "arrow_circle_right";
      font-family: "Material Icons";
      font-size: rem-calc(18);
      right: 0;
      bottom: 4px;
      line-height: 1;
    }
  }
  &__date {
    @include webfont();
    @include font-format(16, 0.05, 28, 400);
    color: $color-gray;
    margin-right: rem-calc(20);
    @include breakpoint(small only) {
      display: block;
    }
  }
  &__text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}
