.c-top-strength {
  padding: rem-calc(45) 0 rem-calc(44);
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  @include breakpoint(small only) {
    display: flex;
    flex-direction: column;
  }
  &__large-bg-text {
    font-size: rem-calc(200);
    @include font-format(125, 0, 189, 500);
    @include webfont-prompt();
    position: absolute;
    color: transparent;
    -webkit-text-stroke: 1px rgba($color-white, 0.3);
    z-index: -1;
    // left: calc(50% - 717px);
    left: rem-calc(-13);
    bottom: 0;
    @include breakpoint(small only) {
      font-size: min(20vw, 200);
      bottom: rem-calc(-25);
    }
  }
  &__content {
    // max-width: 520px;
    // width: 100%;
    width: calc(100% - 682 / 1400 * 100% - 20px);
    min-height: rem-calc(394);
    @include breakpoint(1100px down) {
      width: calc(100% - 682 / 1400 * 100% - 20px);
    }
    @include breakpoint(small only) {
      width: 100%;
      margin-top: rem-calc(30);
    }
  }
  &__heading {
    &.is-white {
      max-width: rem-calc(504);
      .is-ja {
        margin-top: rem-calc(13);
        @include font-format(32, 0.05, 56, 700);
        @include breakpoint(small only) {
          font-size: rem-calc(32) * 0.8;
        }
      }
    }
  }
  &__text {
    color: $color-white;
    margin-top: rem-calc(34);
    @include font-format(16, 0.05, 28, 400);
  }
  &__buttons {
    margin-top: rem-calc(43);
    @include breakpoint(small only) {
      display: flex;
      justify-content: center;
    }
  }
  &__image {
    width: calc(682 / 1400 * 100%);
    position: absolute;
    right: 0;
    height: calc(100% - 45px - 44px);

    @include breakpoint(small only) {
      // height: rem-calc(394);
      // width: 100%;
      position: relative;
      width: 100%;
      padding-top: calc(394 / 682 * 100%);
    }
    .is-bgimg {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      @include bg-option();
    }
  }
}
