/*
---
name: バナー_2カラム
category: Banners
---
*/
@use "sass:math";

.c-banners {
  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    color: $color-white;
    text-align: center;
    width: 100%;
    height: rem-calc(160);
    overflow: hidden;
    @include breakpoint(small only) {
      height: 38vw;
    }
    // *アイコン
    &::after {
      content: "chevron_right";
      font-family: 'Material Icons Outlined';
      line-height: 1;
      letter-spacing: 0;
      font-size: rem-calc(36);
      font-weight: 400;
      position: relative;
      z-index: 99;
      position: absolute;
      right: rem-calc(24);
      @include breakpoint(small only) {
        font-size: rem-calc(36)*0.8;
      }
    }

    // *hover
    &:hover {
      opacity: 1;

      .c-banners__image {
        img {
          transform: translate(-50%, -50%) scale(1.15);
        }
      }
    }
  }

  &__image {
    display: block;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-width: inherit;
      transition: all .25s ease;
      @include breakpoint(small only) {
        max-width: 100%;
        max-height: inherit;
      }
    }
  }

  &__text {
    position: relative;
    z-index: 99;

    span {
      display: block;
      font-size: rem-calc(24);
      letter-spacing: 0.1em;
      line-height: 1.6;
      @include breakpoint(small only) {
        font-size: rem-calc(20);
      }
    }

    small {
      display: block;
      font-size: rem-calc(16);
      letter-spacing: 0.1em;
      @include webfont();
      @include breakpoint(small only) {
        font-size: rem-calc(14);
      }
    }
  }
}

/*
---
name: バナー_3カラム
category: Banners
---
*/
.c-banners {
  //-> 大
  &.is-lg {
    .c-banners__block {
      height: rem-calc(332);
      @include breakpoint(small only) {
        height: 40vw;
      }

      &::after {
        display: none;
      }

      &:hover {
        .c-banners__button {
          &::after {
            transition: .3s;
            right: 0;
          }
        }
      }
    }

    .c-banners__text {
      span {
        @include webfont();
        font-size: rem-calc(36);
        letter-spacing: 0.1em;
        line-height: 1.3;
        position: relative;

        .is-title {
          position: relative;
          font-weight: bold;
          font-size: rem-calc(32);
          line-height: math.div(40, 32)*1;
          padding-bottom: rem-calc(13);

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 30px;
            height: 1px;
            background: $color-white;
          }
        }

        @include breakpoint(small only) {
          font-size: rem-calc(36)*0.8;
        }
      }

      small {
        margin-top: rem-calc(22);
        font-family: $font-base-family;
        font-weight: 700;
      }
    }

    .c-banners__button {
      margin-top: rem-calc(42);
      position: relative;
      color: $color-white;
      padding: rem-calc(15) 0;
      border: 1px solid $color-white;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: rem-calc(15);
        bottom: 0;
        margin: auto;
        width: 30px;
        height: 1px;
        background: $color-white;
        transition: .3s;
      }
    }
  }
}
