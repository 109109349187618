/*
---
name: オファー_通常
category: Layout
---
*/

.l-offer {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: rem-calc(2);
  min-height: rem-calc(317);
  @include breakpoint(medium down) {
    // grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;
  }
  &__catalog {
    text-decoration: none;
    // width: 25%;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 rem-calc(20);
    @include breakpoint(medium down) {
      min-height: rem-calc(220);
      // grid-area: 1/1/2/2;
    }
    &::before {
      content: url(../images/icon-catalog.svg);
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(67);
      height: rem-calc(67);
      background-color: rgba($color-white, 0.7);
      border-radius: 50%;
    }
    .is-bgimg {
      width: 100%;
      height: 100%;
      @include bg-option();
      z-index: -1;
      position: absolute;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color-black, 0.9);
      }
    }
    &__text {
      @include font-format(24, 0.05, 38, 700);
      color: $color-white;
      margin-top: rem-calc(18);
      text-align: center;
      @include breakpoint(small only) {
        text-align: center;
        font-size: rem-calc(22);
      }
      > span {
        @include breakpoint(1230px down) {
          display: block;
        }
      }
    }
  }
  &__store {
    // width: 25%;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    @include breakpoint(medium down) {
      // grid-area: 1/2/2/3;
      min-height: rem-calc(220);
    }
    &__image {
      position: relative;
      width: rem-calc(67);
      height: rem-calc(67);
      background-color: rgba($color-white, 0.7);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        width: rem-calc(36);
        height: rem-calc(37);
        content: "";
        background: url(../images/icon-inventory.svg) no-repeat center center /
          contain;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .is-bgimg {
      width: 100%;
      height: 100%;
      @include bg-option();
      position: absolute;
      z-index: -1;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color-secondary, 0.8);
      }
    }
    &__text {
      @include font-format(24, 0.05, 38, 700);
      color: $color-black;
      margin-top: rem-calc(18);
      @include breakpoint(small only) {
        text-align: center;
        font-size: rem-calc(22);
      }
    }
  }
  &__contact {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    overflow-x: hidden;
    padding: rem-calc(20);
    @include breakpoint(medium down) {
      // grid-area: 2/1/3/3;
      min-height: rem-calc(315);
    }
    &__bg-text {
    }
    .is-bgimg {
      width: 100%;
      height: 100%;
      @include bg-option();
      position: absolute;
      z-index: -1;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color-primary, 0.8);
      }
    }
    &__container {
      max-width: rem-calc(543);
      width: 100%;
    }
    &__content {
      display: flex;
      gap: rem-calc(38);
      margin-top: rem-calc(36);
      @include breakpoint(1170px down) {
        margin-top: rem-calc(20);
        gap: rem-calc(16);
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &__box-tel {
      font-size: rem-calc(28);
      color: $color-white;
      span {
        display: block;
        @include webfont-prompt;
        @include font-format(28, 0.05, 36, 500);
        margin-bottom: rem-calc(10);
        &::before {
          content: "call";
          vertical-align: bottom;
          font-family: "Material Icons";
          margin-right: rem-calc(5);
          color: $color-white;
          font-size: rem-calc(28);
        }
      }
      small {
        display: block;
        @include font-format(14, 0.05, 21, 400);
        padding-left: rem-calc(35);
      }
    }
  }
  &__heading {
    @include font-format(28, 0.05, 28, 700);
    color: $color-white;
    margin: 0;
    text-align: center;
    @include breakpoint(small only) {
      font-size: rem-calc(24);
    }
  }
  &__bg-text {
    position: absolute;
    @include font-format(157, 0, 237, 500);
    @include webfont-prompt();
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.3);
    color: transparent;
    top: rem-calc(-52);
    right: rem-calc(-12);
    @include breakpoint(small only) {
      font-size: rem-calc(100);
      top: rem-calc(-20);
    }
  }
  &__text {
    text-align: center;
    @include font-format(16, 0.05, 28, 400);
    margin-top: rem-calc(27);
    color: $color-white;
    @include breakpoint(small only) {
      margin-top: rem-calc(16);
      font-size: rem-calc(14);
      line-height: 1.5;
    }
  }
}
