// 変数設定
//
// プロジェクトのCSSで利用する変数を定義

// # 1. Color
@use "sass:math";

$color-white: #fff !default; // 白
$color-black: #222222 !default; // 黒

// # プライマリ
$color-primary: #09458b !default; // プライマリカラー
$color-primary-dark: #243b6f !default;
$color-primary-light: #587ea3 !default;

// # セカンダリー
$color-secondary: #ffc400 !default; // セカンダリカラー

// # グレイスケールカラー
$color-tertiary: #cccccc !default; // ターシャリカラー

// # アクセント
$color-accent: #e04b3a !default; // ターシャリカラー
$color-accent-green: #65ac29 !default; // ターシャリカラー
$color-accent-orange: #e6a54d !default; // ターシャリカラー

// # 灰色
$color-gray: #aaaaaa !default; // 灰色

// # 役割別
$color-state-danger: #cc2919 !default; // 危険
$color-state-warning: #cc9e12 !default; // 警告
$color-state-info: #378da3 !default; // お知らせ
$color-state-success: #13a83a !default; // 成功

// # 2. Grid

$grid-row-width: 1140px !default; // グリッドの幅
$grid-column-count: 12 !default; // グリッド数
$grid-column-responsive-gutter: (
  // カラムとカラムの間隔
  small: 16px,
  // モバイル
  medium: 36px,
  // タブレット以上
);

// # 3. font
$font-base-size: 16px !default; // body のフォントサイズ
$font-base-line-height: 1.75 !default; // body のフォントサイズ
$font-base-letter-spacing: 0.1em !default; // body のフォントサイズ
$font-base-color: #222 !default; // body のフォントカラー
$font-base-family: "Noto Sans JP", sans-serif !default; // フォントファミリー
$font-base-weight: normal !default; // フォント幅
$font-base-style: normal !default; // フォントスタイル

//en
$font-base-size-en: 15px !default; // body.en のフォントサイズ
$font-base-line-height-en: 1.8 !default; // body.en のフォントサイズ
$font-base-letter-spacing-en: 0 !default; // body.en のフォントサイズ

$font-heading-sizes: (
  // 見出しのフォントサイズ
  small:
    (
      "h1": 24px,
      "h2": 20px,
      "h3": 19px,
      "h4": 18px,
      "h5": 17px,
      "h6": 16px,
    ),
  medium: (
    "h1": 48px,
    "h2": 40px,
    "h3": 31px,
    "h4": 25px,
    "h5": 20px,
    "h6": 16px,
  )
);

// # 4. Margin

$margin-base: 8px !default;
//$margin-xlg: $margin-base*12.5 !default;
$margin-lg: $margin-base * 8 !default;
$margin-md: $margin-base * 4 !default;
$margin-sm: $margin-base * 2 !default;
$margin-xs: $margin-base !default;

// # 5. border

$border-base-color: #ccc;
$border-base-width: 1px !default;
$border-radius: 3px !default;
$border: $border-base-width solid $border-base-color;

// # 6. breakpoint

$breakpoints: (
  small: 0,
  medium: 750px,
  large: 950px,
  xlarge: 1140px,
  xxlarge: 1440px,
) !default;

$breakpoint-classes: (small medium large) !default;

// # 7. background
$color-background: #fafbff;

// # 8. two-column
$asideContainer: 320;
$asideMargin: 70;
$mainContainer: calc(100% - #{$asideContainer}px - #{$asideMargin}px);

// # SNS color
$color-sns-line: #00b900;
$color-sns-facebook: #1877f2;
$color-sns-twitter: #1da1f2;
$color-sns-youtube: #da1725;
