/*
---
name: ボタン_カラー別
category: Buttons
---
*/
.c-button {
  position: relative;
  outline: none;
  display: inline-block;
  width: 100%;
  max-width: rem-calc(203);
  text-align: center;
  background-color: $color-primary;
  color: $color-white;
  text-decoration: none;
  padding: rem-calc(13) rem-calc(24);
  transition: all 0.3s;
  // *アイコン
  &::after {
    content: "chevron_right";
    font-family: "Material Icons Outlined";
    line-height: 1;
    letter-spacing: 0;
    position: absolute;
    top: 50%;
    right: rem-calc(16);
    transform: translateY(-50%);
    font-weight: 400;
  }

  // *hover
  &:hover {
    opacity: 1;
    background-color: $color-white;
    color: $color-primary;
  }

  // カラー設定
  //文字と背景反転
  &.is-reverse {
    background-color: $color-white;
    color: $color-primary;
    border: 1px solid $color-white;
    &:hover {
      background-color: $color-primary;
      color: $color-white;
      border: 1px solid $color-primary;
    }
  }

  // -> セカンダリカラー
  &.is-secondary {
    background-color: lighten($color-primary, 40);
    color: $color-primary;
    border-color: lighten($color-primary, 40);

    &:hover {
      background-color: $color-white;
      border-color: $color-primary;
    }
  }
}

/*
---
name: ボタン_サイズ別
category: Buttons
---
*/
.c-button {
  // サイズ設定
  // -> 大
  &.is-xlg {
    max-width: rem-calc(392);
    padding: rem-calc(26) rem-calc(32);
    font-size: rem-calc(18);
    @include breakpoint(small only) {
      max-width: 100%;
      font-size: rem-calc(18) * 0.9;
      padding: rem-calc(20) rem-calc(32);
    }

    &::after {
      font-size: rem-calc(24);
      @include breakpoint(small only) {
        font-size: rem-calc(24) * 0.9;
      }
    }
  }

  &.is-md {
    max-width: rem-calc(249);
    @include font-format(14, 0.05, 24.5, 700);
    width: 100%;
    height: rem-calc(56);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid $color-primary;
    border-radius: rem-calc(28);
    &::after {
      position: absolute;
      right: 0;
      content: "arrow_circle_right";
      font-family: "Material Icons";
      color: $color-white;
      font-size: rem-calc(14);
      top: 50%;
      transform: translateY(-50%);
      right: rem-calc(14);
    }
    &:hover {
      &::after {
        color: $color-primary;
      }
    }
    &.is-reverse {
      &::after {
        color: $color-primary;
      }
      &:hover {
        border-color: $color-white;
        &::after {
          color: $color-white;
        }
      }
    }
  }
  // -> 中
  &.is-lg {
    max-width: rem-calc(356);
    padding: rem-calc(16) rem-calc(24);
    @include breakpoint(small only) {
      max-width: 100%;
    }
  }

  // -> 小
  &.is-sm {
    display: block;
    padding: 0;
    width: auto;
    max-width: none;
    padding-bottom: rem-calc(6);
    position: relative;
    display: inline-block;
    @include font-format(14, 0.05, 20, 700);
    padding-right: rem-calc(22);
    position: relative;
    text-decoration: none;
    text-align: start;
    background: none;
    color: $color-primary;
    &::after {
      position: absolute;
      right: 0;
      content: "arrow_circle_right";
      font-family: "Material Icons";
      color: $color-primary;
      font-size: rem-calc(14);
      top: rem-calc(10);
    }
    &::before {
      position: absolute;
      content: "";
      height: 1px;
      width: calc(100% - 22px);
      background-color: $color-primary;
      bottom: 0;
      left: 0;
    }
    &:hover {
      opacity: 0.7;
    }
  }

  // -> 極小
  &.is-xs {
    width: inherit;
    max-width: 100%;
    background-color: transparent;
    padding: 0 rem-calc(16) 0 0;
    border: none !important;
    color: $font-base-color;
    font-size: rem-calc(14);
    letter-spacing: 0.1em;
    @include breakpoint(small only) {
      font-size: rem-calc(14) * 0.9;
    }

    &::after {
      color: $color-primary;
      right: 0;
    }

    // *hover
    &:hover {
      opacity: 0.6;
    }
  }

  &.is-nav {
    color: $color-primary;
    background: $color-white;
    border: 1px solid $color-primary;
    padding: rem-calc(16) rem-calc(24);
    max-width: 100%;
    height: 100%;
    border-radius: $border-radius;

    @include breakpoint(small only) {
      padding: rem-calc(12) rem-calc(24);
    }

    &:after {
      content: "expand_more";
      right: rem-calc(16);
      transform: translateY(-50%);
      font-size: rem-calc(18);
      line-height: 1;

      @include breakpoint(small only) {
        font-size: rem-calc(14);
      }
    }

    &:hover {
      color: $color-gray;
      border-color: $color-gray;
      transform: translateY(6px);
    }
  }

  &.is-arrow-left {
    &:after {
      content: "chevron_left";
      right: auto;
      left: rem-calc(10);
    }
  }
}
