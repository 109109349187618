.c-relation {

  &__title {

    &.c-heading.is-sm {
      margin-bottom: rem-calc(40);

      @include breakpoint(small only) {
        margin-bottom: rem-calc(24);
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem-calc(-18);

    @include breakpoint(small only) {
      display: block;
      margin: 0;
    }

    &.is-center {
      justify-content: center;
    }
  }

  &__button {
    width: calc(33.3% - 36px);
    margin: 0 rem-calc(18);

    @include breakpoint(medium down) {
      width: calc(50% - 36px);
    }

    @include breakpoint(small only) {
      width: 100%;
      margin: 0;
    }

    &:nth-child(n + 4) {
      margin-top: rem-calc(36);
    }

    &:nth-child(n + 3) {

      @include breakpoint(medium down) {
        margin-top: rem-calc(36);
      }
    }

    &:not(:first-child) {

      @include breakpoint(small only) {
        margin-top: rem-calc(16);
      }
    }

    .c-button {
      max-width: 100%;
    }
  }
}
