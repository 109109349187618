/*
---
name: ヒーローブロック_通常
category: SpecialComponents
---
*/

@use "sass:math";

.c-hero-block {
  overflow: hidden;

  &__block {
    margin-bottom: rem-calc(80);
    position: relative;
    min-height: rem-calc(480);
    padding-top: rem-calc(80);
    @include breakpoint(small only) {
      margin-bottom: rem-calc(48);
      min-height: inherit;
      padding-top: 0 !important;
    }
    // 偶数番目
    &:nth-child(even),
    &.is-reverse {
      .c-hero-block__image {
        left: auto;
        right: 0;
      }

      .c-hero-block__content {
        margin-left: 0;
      }

    }

    //*最後のブロック
    &:last-child {
      margin-bottom: rem-calc(8);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: math.div(880, 1600)*100%;
    height: rem-calc(480);
    overflow: hidden;
    @include breakpoint(small only) {
      position: relative;
      width: 100%;
      height: 55vw;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-width: inherit;
      @include breakpoint(small only) {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__content {
    background-color: $color-white;
    position: relative;
    z-index: 99;
    width: math.div(588, 1140)*100%;
    min-height: rem-calc(312);
    margin-left: auto;
    padding: rem-calc(64);
    border: 1px solid $color-primary;
    @include breakpoint(small only) {
      width: 100%;
      min-height: inherit;
      margin-top: rem-calc(-24);
      padding: rem-calc(24);
    }
  }

  &__detail {
    color: $color-primary;
    font-weight: 400;
  }

  //*数字
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(24);
    @include breakpoint(small only) {
      margin-bottom: rem-calc(24)*0.5;
    }

    small {
      @include webfont();
      font-size: rem-calc(100);
      opacity: 0.25;
      line-height: 1;
      letter-spacing: 0;
      cursor: pointer;
      margin-right: rem-calc(16);
      @include breakpoint(small only) {
        font-size: rem-calc(100)*0.5;
        margin-right: rem-calc(16)*0.5;
      }
    }

    span {
      @include webfont();
      font-size: rem-calc(28);
      letter-spacing: 0.1em;
      @include breakpoint(small only) {
        font-size: rem-calc(28)*0.85;
      }
    }
  }

  &__button {
    margin-top: rem-calc(32);
    text-align: right;
    @include breakpoint(small only) {
      margin-top: rem-calc(32)*0.5;
    }
  }
}
