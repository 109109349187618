.c-block-modal {
  cursor: auto;
  text-align: left;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color-black,0.7);
  z-index: 9999999;
  @include transition();
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;

  &.is-open {
    opacity: 1;
    visibility: visible;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__container {
    z-index: 1;
    position: relative;
  }

  &__wrapper {
    height: auto;
    max-height: calc(80vh - 84px);
    overflow-y: scroll;
    padding: rem-calc(30);

    //paddingに関してはmodaal-content-containerとうまく調整してください
    @include breakpoint(medium down) {
      max-height: calc(80vh - 120px);
      padding: 0;
    }
  }

  &__head {

    &.c-heading.is-lg {
      text-align: center;
      margin-top: 0;
    }
  }

  &__content {

    &:not(:first-child) {
      margin-top: rem-calc(80);

      @include breakpoint(small only) {
        margin-top: rem-calc(40);
      }
    }
  }

  &__title {

    &.c-heading.is-md {
      margin: 0 0 rem-calc(40);

      @include breakpoint(small only) {
        margin-bottom: rem-calc(24);
      }
    }
  }
}
