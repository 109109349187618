/*
---
name: ヘッダー
category: Layout
---
*/
@use "sass:math";

.l-header {
  // background-color: $color-white;

  background-color: transparent;
  width: 100%;
  z-index: 9990;
  position: absolute;
  left: 0;
  top: 0;
  @include transition();

  &__inner {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include breakpoint(medium down) {
      flex-wrap: wrap;
    }
  }

  &__search {
    //border: 1px solid $border-base-color;
    //color: $font-base-color;
    position: relative;
    display: block;
    margin-left: 1.7857142857rem;
    max-width: 11.4285714286rem;
    width: 100%;
    border: 1px solid $font-base-color;
    color: $font-base-color;
    background: none;
    border-radius: 16px;
    font-size: 0.7142857143rem;
    letter-spacing: 0.1em;
    line-height: 1.5;
    font-weight: 400;
    padding: 0.5rem 1.2857142857rem 0.5rem 2.7142857143rem;
    transition: all 0.2s ease-out;

    &:before {
      position: absolute;
      left: rem-calc(18);
      top: 50%;
      transform: translateY(-50%);
      line-height: 1;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      font-family: "Material Icons Outlined";
      content: "search";
      font-feature-settings: "liga";
      font-size: rem-calc(18);
    }

    &:hover,
    &.is-active {
      background: $color-primary;
      color: $color-white;
      border-color: $color-primary;
    }
  }

  &__search-icon {
    background: none;
    border: none;
    border-radius: 0;
    line-height: 1;
    font-size: rem-calc(20);
    margin-left: auto;
  }

  &__logo {
    margin: 0;
    // width: 175px;
    flex-shrink: 0;
    height: 100%;
    @include breakpoint(950px up) {
      padding: rem-calc(14) 0;
      // width: 148px;
    }

    a,
    img {
      display: block;
    }
    a {
      display: flex;
      flex-direction: column;
      color: $color-white;
      text-decoration: none;
      small {
        @include font-format(10, 0.05, 15, 700);
        // color: $color-white;
        margin-bottom: rem-calc(6);
        @include breakpoint(medium down) {
          margin-bottom: rem-calc(2);
        }
      }
    }
    &__content {
      img {
        @include breakpoint(medium down) {
          width: rem-calc(220);
        }
        &.is-white {
          display: block;
        }
        &.is-blue {
          opacity: 0;
          visibility: hidden;
          display: none;
        }
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    // padding: rem-calc(16) rem-calc(32) rem-calc(24);
    padding-left: rem-calc(32);
    height: rem-calc(80);

    @include breakpoint(medium down) {
      padding: rem-calc(6) rem-calc(64) rem-calc(6) rem-calc(16);
      height: rem-calc(60);
    }
  }

  &__nav {
    margin-left: auto;
    height: 100%;
    padding: rem-calc(39) 0 rem-calc(8);
    @include breakpoint(medium down) {
      display: none;
    }

    ul {
      display: flex;
      // align-items: center;
      width: 100%;

      li {
        line-height: 1;
        &:not(:last-of-type) {
          margin-right: 32px;
        }

        &:hover {
          .l-header__submenu {
            visibility: visible;
            opacity: 1;
            transition: all 0.3s;
          }
        }

        > a {
          text-decoration: none;
          color: $color-white;
          @include font-format(16, 0.05, 17, 700);
          position: relative;
          padding-bottom: rem-calc(25);
          transition: all 0.3s ease;
          //装飾

          //*hover
          &:hover {
            // opacity: 1;
            color: $color-primary;

            // &::after {
            //   width: 100%;
            //   opacity: 1;
            // }
          }
        }
      }
    }
  }

  &__submenu {
    position: absolute;
    bottom: rem-calc(1);
    left: 0;
    transform: translateY(100%);
    width: 100%;
    z-index: 9999;
    background-color: $color-background;
    padding-top: rem-calc(64);
    padding-bottom: rem-calc(67);
    visibility: hidden;
    opacity: 0;
    transition: all 0.05s;
    @media (max-width: 1280px) {
      padding-left: rem-calc(24);
      padding-right: rem-calc(24);
    }
    &__outer {
      display: flex;
      width: 100%;
      max-width: rem-calc(1140);
      margin: 0 auto;
    }
    &__title {
      width: rem-calc(294);
      padding-top: rem-calc(21);
      span {
        @include font-format(28, 0.05, 30, 700);
        letter-spacing: 0.05em;
        display: block;
      }
      a {
        padding-bottom: rem-calc(6);
        position: relative;
        display: inline-block;
        @include font-format(14, 0.05, 20, 700);
        margin-top: rem-calc(34);
        padding-right: rem-calc(22);
        position: relative;
        text-decoration: none;
        &::after {
          position: absolute;
          right: 0;
          content: "arrow_circle_right";
          font-family: "Material Icons";
          color: $color-primary;
          font-size: rem-calc(14);
        }
        &::before {
          position: absolute;
          content: "";
          height: 1px;
          width: calc(100% - 22px);
          background-color: $color-primary;
          bottom: 0;
          left: 0;
        }
      }
    }
    &__content {
      width: calc(100% - 294px);
    }
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: rem-calc(36);
    }
    &__block {
      // width: math.div(230, 750) * 100%;
      // padding-bottom: 0 !important;
      // margin-right: math.div(30, 750) * 100%;
      // margin-bottom: rem-calc(24);
      @include font-format(16, 0.05, 28, 700);
      color: $color-black;
      text-decoration: none;
      opacity: 1 !important;
      position: relative;
      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        content: "arrow_circle_right";
        font-family: "Material Icons";
        color: $color-primary;
        font-size: rem-calc(16);
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      span {
        font-size: rem-calc(15);
        font-weight: 700;
        margin-top: rem-calc(10);
        display: block;
        padding-right: rem-calc(22);
      }
      &:hover {
        color: $color-primary;
        .l-header__submenu__image > img {
          transform: scale(1.1);
        }
      }
    }
    &__image {
      max-width: rem-calc(258);
      aspect-ratio: 258 / 158;
      overflow: hidden;
      img {
        object-fit: cover;
        object-position: center center;
        aspect-ratio: 258 / 158;
        @include transition();
      }
    }
    &__parent {
      position: relative;
      &::after {
        position: absolute;
        content: "expand_less";
        font-family: "Material Icons";
        @include font-format(18, 0, 22, 400);
        transform: rotate(-180deg) translateX(50%);
        left: 50%;
        bottom: rem-calc(7);
      }
    }
  }
  &__buttons {
    height: 100%;
    display: flex;
    justify-content: end;
    margin-left: rem-calc(56);
    max-width: rem-calc(280);
    width: 100%;
    @include breakpoint(1000px down) {
      margin-left: rem-calc(20);
      max-width: rem-calc(240);
    }
    @include breakpoint(medium down) {
      display: none;
    }
  }
  &__button {
    //*矢印削除
    display: block;
    max-width: rem-calc(140);
    width: 100%;
    padding: rem-calc(13) rem-calc(16) rem-calc(15);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include font-format(14, 0.05, 20, 700);
    // margin-left: rem-calc(24);
    @include breakpoint(1000px down) {
      max-width: rem-calc(120);
      padding: rem-calc(13) rem-calc(8) rem-calc(15);
    }
    &.is-store {
      background-color: $color-secondary;
      color: $color-black;
      &::before {
        content: "";
        background: url(../images/icon-inventory.svg) no-repeat center center /
          contain;
        display: block;
        width: 29px;
        height: 29px;
      }
    }
    &.is-contact {
      background-color: $color-primary;
      color: #fff;
      &::before {
        content: "";
        background: url(../images/icon-mail.svg) no-repeat center center /
          contain;
        display: block;
        width: 29px;
        height: 29px;
      }
    }
    @include breakpoint(medium down) {
      display: none;
    }

    &::after {
      display: none;
    }

    //*アイコン
    span {
      font-size: rem-calc(16);
      padding-right: rem-calc(8);
      vertical-align: sub;
    }
  }

  &__other {
    margin-left: auto;
    display: flex;
    align-items: center;

    @include breakpoint(medium down) {
      display: none;
    }
  }

  &__text {
    @include font-format(12, 0, 18, 400);
  }

  &__tel {
    margin-left: rem-calc(38);
    @include webfont();
    @include font-format(24, 0, 28, 700);

    i {
      font-size: rem-calc(22);
      margin-right: rem-calc(8);
    }
  }
}

.l-header:hover {
  background-color: $color-white;
  .l-header {
    &__logo {
      a {
        color: $color-primary;
      }
      &__content {
        .is-white {
          display: none;
        }
        .is-blue {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &__nav {
      ul li {
        > a {
          color: $color-black;
          &:hover {
            opacity: 1;
            color: $color-primary;
          }
        }
      }
    }
  }
}

// スマホメニューopen時
.is-slidebar-active {
  .l-header {
    position: fixed;
    top: 0;
    left: 0;
  }
}

.l-header__submenu.is-active {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s;
}

.home .is-fixed.l-header {
  position: fixed;
  top: 0;
  background-color: $color-white;
  .l-header {
    &__logo {
      a {
        color: $color-primary;
      }
      &__content {
        .is-white {
          display: none;
        }
        .is-blue {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &__nav {
      ul li {
        > a {
          color: $color-black;
          &:hover {
            opacity: 1;
            color: $color-primary;
          }
        }
      }
    }
  }
}
