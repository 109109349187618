.c-top-product {
  padding-top: rem-calc(98);
  padding-bottom: rem-calc(120);
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  @include breakpoint(small only) {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(120);
  }
  &__caption {
    position: absolute;
    @include font-format(10, 0.05, 17.5, 400);
    color: $color-white;
    left: rem-calc(10);
    top: rem-calc(12);
  }
  &__large-bg-text {
    font-size: rem-calc(200);
    @include font-format(200, 0, 302, 500);
    @include webfont-prompt();
    position: absolute;
    color: transparent;
    -webkit-text-stroke: 1px rgba($color-primary, 0.3);
    z-index: -1;
    // right: calc(50% - 745px);
    right: rem-calc(-47);
    top: rem-calc(-22);
    @include breakpoint(small only) {
      right: -4%;
      font-size: min(20vw, 200);
    }
  }
  &__text {
    margin-top: rem-calc(31);
  }
  &__large-block {
    margin-top: rem-calc(56);
    width: 100%;
    &__bg {
      width: 100%;
      height: rem-calc(415);
      @include bg-option();
      @include breakpoint(small only) {
        height: rem-calc(250);
      }
    }
  }
  &__box {
    background-color: $color-white;
    margin-top: rem-calc(-174);
    padding: rem-calc(58) rem-calc(56) rem-calc(54);
    display: flex;
    gap: rem-calc(20);
    justify-content: space-between;
    filter: drop-shadow(0 rem-calc(2) rem-calc(20) rgba(#1b33b2, 0.1));
    @include breakpoint(medium down) {
      flex-direction: column;
      margin-top: rem-calc(-60);
    }
    @include breakpoint(small only) {
      padding: rem-calc(30) rem-calc(20) rem-calc(30);
    }
    &__content {
      max-width: rem-calc(490);
      width: 100%;
      @include breakpoint(medium down) {
        max-width: none;
      }
    }
    &__logo {
      margin-bottom: rem-calc(15);
    }
    &__titles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoint(small only) {
        flex-direction: column;
        align-items: start;
        gap: 8px;
      }
    }
    &__title {
      margin: 0;
      @include font-format(23, 0.05, 40, 700);
      @include breakpoint(small only) {
        font-size: rem-calc(20);
      }
    }
    &__text {
      margin-top: rem-calc(23);
      @include font-format(14, 0.05, 24.5, 400);
      &.is-mt-sm {
        margin-top: rem-calc(12);
      }
    }
    &__links {
      padding: rem-calc(22) rem-calc(34) rem-calc(18);
      background-color: $color-background;
      max-width: rem-calc(498);
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 0.75fr;
      gap: rem-calc(22) rem-calc(24);
      @include breakpoint(medium down) {
        max-width: none;
      }
      @include breakpoint(small only) {
        padding: rem-calc(22) rem-calc(16) rem-calc(18);
      }
    }
  }
  &__blocks {
    margin-top: rem-calc(64);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: rem-calc(12);
    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
      gap: rem-calc(40);
    }
  }
  &__block {
    &__bg {
      padding-top: calc(257 / 564 * 100%);
      position: relative;
      .is-bgimg {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @include bg-option();
      }
    }
  }
  &__small-box {
    max-width: rem-calc(484);
    width: calc(484 / 564 * 100%);
    min-height: rem-calc(235);
    background-color: $color-white;
    margin: rem-calc(-32) auto 0;
    padding: rem-calc(39) rem-calc(44) rem-calc(46) rem-calc(40);
    filter: drop-shadow(0 rem-calc(2) rem-calc(20) rgba(#1b33b2, 0.1));
    @include breakpoint(medium down) {
      max-width: rem-calc(720);
    }
    @include breakpoint(small only) {
      padding: rem-calc(28) rem-calc(20) rem-calc(26);
    }
  }
  &__button {
    @include breakpoint(small only) {
      margin-left: auto;
    }
  }
}
