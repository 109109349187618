.c-top-case {
  padding-top: rem-calc(102);
  padding-bottom: rem-calc(120);
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  @include breakpoint(small only) {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(160);
  }
  .row {
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0 rem-calc(56);
    @include breakpoint(small only) {
      flex-direction: column;
    }
  }
  &__large-bg-text {
    font-size: rem-calc(200);
    @include font-format(200, 0, 302, 500);
    @include webfont-prompt();
    position: absolute;
    color: transparent;
    -webkit-text-stroke: 1px rgba($color-primary, 0.3);
    z-index: -1;
    // right: calc(50% - 745px);
    right: rem-calc(-42);
    top: rem-calc(-46);
    @include breakpoint(small only) {
      right: -4%;
      top: 0;
      font-size: min(20vw, 200);
    }
  }
  &__text {
    margin-top: rem-calc(19);
    letter-spacing: 0.05em;
  }
  &__head {
    width: 50%;
    @include breakpoint(small only) {
      width: 100%;
    }
  }
  &__buttons {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 8px;
    @include breakpoint(small only) {
      width: 100%;
      position: absolute;
      bottom: rem-calc(20);
      left: 50%;
      transform: translateX(-50%);
      flex-direction: column;
      align-items: center;
    }
    .c-button {
      margin-left: rem-calc(33);
      @include breakpoint(medium down) {
        margin-left: rem-calc(20);
        max-width: rem-calc(180);
      }
      @include breakpoint(small only) {
        margin: 20px auto 0;
        max-width: 15.5625rem;
      }
    }
  }
  &__slider {
    position: relative;
    &__item {
      padding-right: rem-calc(36);
      a {
        display: block;
        text-decoration: unset !important;
      }
    }
    &__image {
      img {
        object-fit: cover;
        aspect-ratio: 423 / 266;
        object-position: center center;
      }
    }
    &__content {
      .is-title {
        @include font-format(18, 0.05, 31.6, 700);
        margin: rem-calc(16) 0 rem-calc(12);
        color: $color-black;
        @include breakpoint(small only) {
          font-size: calc(rem-calc(18) * 0.8);
        }
      }
      .is-company {
        @include font-format(16, 0.05, 28, 300);
        color: $color-gray;
        margin-bottom: rem-calc(7);
        @include breakpoint(small only) {
          font-size: calc(rem-calc(16) * 0.8);
        }
      }
      .is-category {
        @include font-format(16, 0.05, 28, 300);
        color: $color-primary;
        @include breakpoint(small only) {
          font-size: calc(rem-calc(16) * 0.8);
        }
      }
    }
    &__button {
      margin: 0 6px;
      background: $color-white;
      border-radius: 50%;
      border: 1px solid $color-gray;
      width: rem-calc(56);
      height: rem-calc(56);
      position: relative;
      @include transition();
      @include breakpoint(small only) {
        width: rem-calc(40);
        height: rem-calc(40);
      }
      &:hover {
        opacity: 0.7;
      }
      .material-icons-outlined {
        @include font-format(18, 0, 24, 500);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $color-primary;
      }
    }
    &__buttons-slider {
      display: flex;
    }
  }
}
