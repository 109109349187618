.modaal{
  &-wrapper{
    z-index: 999999 !important;
  }
  &-outer-wrapper{}

  &-inner-wrapper{}

  &-overlay{
    z-index: 99999 !important;
  }
}

.modaal-close {

  &:hover {

    &:before, &:after {
      background: $color-primary;
    }
  }
}

.modaal-video-wrap {
  margin: auto;
}
