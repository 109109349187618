// パンくずナビゲーション
// Styleguide 2.3.0
.c-breadcrumb {
  padding: rem-calc(12) 0;
  @include breakpoint(medium down) {

  }

  &__inner {

    @include breakpoint(small only) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      @include font-format-light(12,19);

      &.is-arrow {
        margin:0 rem-calc(8);
        align-items: center;

        span {
          font-size: rem-calc(12);
          vertical-align: middle;
        }
      }

      a {
        @include font-format-light(12,19);
      }
    }
  }
}
