.c-forms{
  margin-top: rem-calc(42);
  padding: rem-calc(42) rem-calc(32);
  background: $color-white;

  @include breakpoint(medium down) {
    padding: rem-calc(42) 0;
  }

  @include breakpoint(small only) {
    padding: rem-calc(36) rem-calc(16);
  }

  &__text{
    text-align: center;
    margin-bottom: rem-calc(48);

    @include breakpoint(small only) {
      margin-bottom: rem-calc(24);
    }
  }

  &__blocks {
    margin-bottom: rem-calc(32);

    @include breakpoint(small only) {
      margin-bottom: rem-calc(24);
    }
  }

  &__block {
    display: flex;
    &:not(:last-child) {
      margin-bottom: rem-calc(32);
      @include breakpoint(small only) {
        margin-bottom: rem-calc(24);
      }
    }
    @include breakpoint(small only) {
      display: block;
    }

    &.is-vertical {
      display: block;

      .c-forms__title {
        max-width: rem-calc(210);
        margin-bottom: rem-calc(16);

        @include breakpoint(small only) {
          max-width: 100%;
          margin-bottom: rem-calc(12);
        }
      }
    }
  }
  &__title {
    min-width: rem-calc(210);
    //padding: rem-calc(16) rem-calc(32);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: none;
    margin-right: rem-calc(60);
    letter-spacing: 0;
    @include breakpoint(small only) {
      width: 100%;
      background-color: transparent;
      margin-bottom: rem-calc(12);
      font-weight: 700;
    }
    &.is-vertical-top {
      align-items: flex-start;
      &.is-just{
        padding-top: rem-calc(40);
        @include breakpoint(small only) {
          padding-top: rem-calc(12);
        }
      }
      .c-forms__label {
        margin-top: rem-calc(3);
      }
    }
  }
  //ラベル
  &__label {
    display: block;
    background-color: $color-accent;
    color: $color-white;
    font-size: rem-calc(12);
    letter-spacing: 0.1em;
    text-align: center;
    padding: rem-calc(1) rem-calc(10);
    margin-left: rem-calc(16);
    min-width: rem-calc(48);
  }
  &__content {
    width: 100%;
    //padding: rem-calc(16) rem-calc(32);
    @include breakpoint(small only) {
      //padding: rem-calc(12) 0;
    }
    &.is-column{
      display: flex;
      justify-content: space-between;
      .c-forms__input {
        margin-right: 24px;
        &:last-of-type {
          margin-right: 0;
        }
      }
      input{
        width: 100%;
      }
    }
    &.is-vertical-middle {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  //インプット
  &__input {
    width: 100%;
    input {
      border-radius: 4px;
      background: #F9F9F9;
      padding: rem-calc(16) rem-calc(16) rem-calc(16) rem-calc(24);
      @include breakpoint(small only) {
        padding: rem-calc(12) rem-calc(16);
      }
      &:focus{
        border-color: $color-primary;
      }
    }
    &.is-sm {
      width: rem-calc(180);
      input {
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(12);
      }
    }
  }
  &__select {
    width: 100%;
    select {
      outline: none;
      border-radius: 4px !important;
      background: #F9F9F9;

      &:focus{
        border-color: $color-primary;
      }
    }
    &.is-sm {
      width: rem-calc(180);
    }
  }

  &__textarea {
    textarea {
      border-radius: 4px;
      background: #F9F9F9;
      padding: rem-calc(16) rem-calc(16) rem-calc(16) rem-calc(24);
      @include breakpoint(small only) {
        padding: rem-calc(12) rem-calc(16);
      }
      &:focus{
        border-color: $color-primary;
      }
    }
  }
  //ラジオボタン,チェックボックス
  &__radio,
  &__checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      & > span {
        margin-left: rem-calc(10);
      }
    }

    & > span {
      margin: rem-calc(5) 0;

      @include breakpoint(small only) {
        margin: rem-calc(3) 0;
      }
    }

    span {

      &:not(:last-child) {
        margin-right: rem-calc(24);

        @include breakpoint(small only) {
          margin-right: rem-calc(16);
        }
      }

      &:last-of-type {
        label {
          margin-right: 0;
        }
      }

      &.is-fullwidth {
        width: 100%;
      }
    }

    &.is-design {

      input {
        cursor: pointer;
        border-color: $border-base-color;

        &[type='radio'],&[type='checkbox'] {
          appearance: none;
          width: 25px;
          height: 25px;
          position: relative;

          @include breakpoint(small only) {
            width: rem-calc(20);
            height: rem-calc(20);
          }
        }

        &[type='radio']:after,&[type='checkbox']:after {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          top: 50%;
          left: -1px;
          transform: translateY(-50%);
          position: relative;
          background-color: $color-white;
          content: '';
          display: inline-block;
          visibility: visible;
          border: 1px solid $border-base-color;

          @include breakpoint(small only) {
            width: rem-calc(20);
            height: rem-calc(20);
          }
        }

        &[type='radio']:before {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translateY(-50%);
          background-color: $color-primary;
          content: '';
          display: inline-block;
          visibility: visible;
          border: 1px solid $color-primary;
          z-index: 10;
          opacity: 0;
          visibility: hidden;

          @include breakpoint(small only) {
            width: rem-calc(12);
            height: rem-calc(12);
            left: 3px;
          }
        }

        &[type='radio']:checked:after,&[type='checkbox']:checked:after {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          top: 50%;
          left: -1px;
          transform: translateY(-50%);
          position: relative;
          background-color: $color-white;
          content: '';
          display: inline-block;
          visibility: visible;
          border: 1px solid $color-primary;

          @include breakpoint(small only) {
            width: rem-calc(20);
            height: rem-calc(20);
          }
        }

        &[type='radio']:checked:before,&[type='checkbox']:checked:before {
          visibility: visible;
          opacity: 1;
        }


        &[type='checkbox']:after {
          border-radius: 0;
        }

        &[type='checkbox']:checked:after {
          content: "check";
          font-family: "Material Icons Outlined";
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0;
          background: $color-primary;
          color: $color-white;
          font-size: rem-calc(20);
          line-height: 1;
          letter-spacing: 0;
        }
      }
    }

    &.is-border {

      label {
        padding: rem-calc(12) rem-calc(16);
        border: 1px solid $border-base-color;
        border-radius: $border-radius;

        @include breakpoint(small only) {
          padding: rem-calc(8) rem-calc(12);
        }
      }
    }
  }

  &__radio {
    &.is-vertical {
      display: block;

      span {
        display: block;
        &:not(:last-child) {
          margin-bottom: rem-calc(6);
        }
      }
    }
  }
  &__checkbox {

    &.is-vertical {
      display: block;

      span {
        display: block;
        &:not(:last-child) {
          margin-bottom: rem-calc(6);
        }
      }
    }
    input {
      margin-top: rem-calc(2);
    }
  }
  &__privacy {
    text-align: center;
    @include breakpoint(small only) {
      margin-top: rem-calc(16);
    }
    input{
      margin-right: rem-calc(10);
    }
    a {
      font-weight: 400;
      text-decoration: underline;
      color: $font-base-color;
    }
  }
  &__flex-al{
    display: flex;
    align-items: center;
  }
  &__flexbox {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(16);
    @include breakpoint(small only) {
      display: block;
    }
    input{
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      min-width: rem-calc(120);
      display: block;
      @include breakpoint(small only) {
        min-width: 100%;
        margin-bottom: rem-calc(8);
      }
    }
  }
  // 住所自動入力ボタン
  &__button {
    flex: none;
    max-width: rem-calc(150);
    border: 1px solid $font-base-color;
    outline: none;
    background-color: $color-white;
    padding: rem-calc(3) rem-calc(12);
    display: block;
    text-align: center;
    border-radius: 4px;
    margin-left: rem-calc(16);
    @include breakpoint(small only) {
      padding: rem-calc(9) rem-calc(12);
    }
  }
  // 送信ボタン
  &__submit {
    text-align: center;
    margin-top: rem-calc(40);
    @include breakpoint(small only) {
      margin-top: rem-calc(32);
    }
    &__back{
      width: calc(25% - 28px);
      // -2px はタグの改行によって発生するホワイトスペースの距離
      margin-right:(map_get($grid-column-responsive-gutter,medium) * 0.5) - 2px;
      @include breakpoint(medium down) {
        width: calc(33.33333% - 28px);
      }
      @include breakpoint(small only) {
        width: calc(50% - 10px);
        margin-right: map_get($grid-column-responsive-gutter,small) * 0.5 - 2px;
      }
    }
    &__submit{
      width: calc(25% - 28px);
      margin-left: map_get($grid-column-responsive-gutter,medium) * 0.5 - 2px;
      @include breakpoint(medium down) {
        width: calc(33.33333% - 28px);
      }
      @include breakpoint(small only) {
        width: calc(50% - 10px);
        margin-left: map_get($grid-column-responsive-gutter,small) * 0.5 - 2px;
      }
    }
  }
}
