// テキスト
//
// Styleguide 1.2.0

// テキスト - 通常
//
// Styleguide 1.2.1

p,
.u-text-normal {
  margin: 0;
}

// テキスト - 縮小
//
// Styleguide 1.2.2

small,
.u-text-small {
  font-size: 0.85em;
}
body.en{
  //small,
  //.u-text-small {
  //  font-size: 0.85em;
  //}
}

// テキスト - 強調
//
// Styleguide 1.2.3

strong,
.u-text-strong {
  font-weight: 700;
}

// テキスト - 打ち消し線
//
// Styleguide 1.2.4

del,
.u-text-del {
  text-decoration: line-through;
}

// テキスト - リンク
// Styleguide 1.2.5

a,
.u-text-link {
  color: $color-primary;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}
a{

  &.is-pdf {

    &:after {
      content: "picture_as_pdf";
      font-family: 'Material Icons Outlined';
      line-height: 1;
      letter-spacing: 0;
    }
  }

  &.is-map {
    //下線が気になるようであれば下記を使用
    //text-underline-offset: 3px;

    &::before {
      content: "location_on";
      font-family: 'Material Icons Outlined';
      line-height: 1;
      letter-spacing: 0;
    }
  }

  &.is-outlink {

    &::after {
      content: "open_in_new";
      font-family: 'Material Icons Outlined';
      line-height: 1;
      letter-spacing: 0;
    }
  }
}

a:hover,
.u-text-link:hover,
.u-text-link.is-hover {
  opacity: 0.8;
}

a:active,
.u-text-link:active,
.u-text-link.is-active {
  // color: darken($color-primary, 10);
}

// p a:visited {
//   color: #887EBF;
// }


// 用途別テキストカラー
.u-text-danger {
  color: $color-state-danger;
}

.u-text-info {
  color: $color-state-info;
}

.u-text-success {
  color: $color-state-info;
}
