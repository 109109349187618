/*
---
name: 見出し_テンプレート
category: Heading
---
*/

.c-heading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.5;

  span {
    font-size: 0.65em;
  }
}

// h1
.c-heading.is-xlg {
  color: $color-primary;
  margin-bottom: rem-calc(24);
  @include breakpoint(small only) {
  }

  // b {
  //   &.is-eng {
  //     display: block;
  //     // @include font-format(42, 0.1, 52);
  //     @include font-format(16, 0.5, 24, 500);
  //     @include breakpoint(small only) {
  //       font-size: rem-calc(42) * 0.8;
  //     }
  //   }
  // }
  .is-eng {
    display: block;
    @include webfont-prompt();
    // @include font-format(42, 0.1, 52);
    @include font-format(16, 0.05, 24, 500);
    display: flex;
    align-items: center;
    @include breakpoint(small only) {
      font-size: rem-calc(16) * 0.8;
    }
    &::before {
      content: "";
      display: block;
      width: rem-calc(14);
      height: rem-calc(14);
      background: url(../images/logo-icon-bl.svg) no-repeat center center /
        contain;
      margin-right: rem-calc(5);
    }
  }

  // span {
  //   &.is-ja {
  //     display: block;
  //     @include font-format(40, 0.5, 72, 700);
  //     // @include font-format-light(16, 26);
  //     @include breakpoint(small only) {
  //       font-size: rem-calc(16) * 0.8;
  //     }
  //   }
  // }
  .is-ja {
    display: block;
    @include font-format(40, 0.05, 56, 700);
    margin-top: rem-calc(11);
    color: $color-black;
    // @include font-format-light(16, 26);
    @include breakpoint(small only) {
      font-size: rem-calc(40) * 0.8;
    }
  }
  &.is-white {
    .is-eng {
      color: $color-white;
      &::before {
        background-image: url(../images/logo-icon.svg);
      }
    }
    .is-ja {
      color: $color-white;
    }
  }
}

// h2
.c-heading.is-lg {
  color: $color-primary;
  @include font-format-light(32, 40);
  margin-bottom: rem-calc(24);
  @include breakpoint(small only) {
    font-size: rem-calc(32) * 0.8;
    margin-bottom: rem-calc(24) * 0.5;
  }

  b {
    &.is-eng {
    }
  }

  span {
    &.is-ja {
      display: block;
      @include webfont();
      @include font-format(
        14,
        null,
        20,
        normal
      ); //line-heightはnullにするとCSS出力をスキップします
      margin-top: rem-calc(6);
      @include breakpoint(small only) {
        font-size: rem-calc(14) * 0.8;
      }
    }
  }
}

// h3
.c-heading.is-md {
  @include font-format-light(28, 38);
  margin-bottom: rem-calc(20);
  @include breakpoint(small only) {
    font-size: rem-calc(28) * 0.8;
    margin-bottom: rem-calc(20) * 0.5;
  }
}

// h4
.c-heading.is-sm {
  @include font-format-light(24, 34);
  @include breakpoint(small only) {
    font-size: rem-calc(24) * 0.8;
  }
}

// h5
.c-heading.is-xs {
  @include font-format-light(20, 30);
  margin-bottom: rem-calc(16);
  @include breakpoint(small only) {
    font-size: rem-calc(20) * 0.8;
    margin-bottom: rem-calc(16) * 0.5;
  }
}

// h6
.c-heading.is-xxs {
  @include font-format-light(18, 28);
  margin-bottom: rem-calc(6);
  @include breakpoint(small only) {
    font-size: rem-calc(18) * 0.8;
  }
}

.c-heading.is-color-primary {
  color: $color-primary;
}

.c-heading.is-color-white {
  color: $color-white;
}

// マージン調整
.c-heading.is-top {
  margin-bottom: 0 !important;
}

.c-heading.is-bottom {
  margin-top: 0 !important;
}

.c-heading.is-center {
  text-align: center;
}

.c-heading.is-mg-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
